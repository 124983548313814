@font-face {
    font-family: 'IBM Plex Sans';
    src: url('src/ibm-plex-sans/IBMPlexSans-LightItalic.woff2') format('woff2'),
        url('src/ibm-plex-sans/IBMPlexSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('src/ibm-plex-sans/IBMPlexSans-BoldItalic.woff2') format('woff2'),
        url('src/ibm-plex-sans/IBMPlexSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('src/ibm-plex-sans/IBMPlexSans-ThinItalic.woff2') format('woff2'),
        url('src/ibm-plex-sans/IBMPlexSans-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('src/ibm-plex-sans/IBMPlexSans-Medium.woff2') format('woff2'),
        url('src/ibm-plex-sans/IBMPlexSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('src/ibm-plex-sans/IBMPlexSans-SemiBoldItalic.woff2') format('woff2'),
        url('src/ibm-plex-sans/IBMPlexSans-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('src/ibm-plex-sans/IBMPlexSans-Bold.woff2') format('woff2'),
        url('src/ibm-plex-sans/IBMPlexSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('src/ibm-plex-sans/IBMPlexSans-ExtraLight.woff2') format('woff2'),
        url('src/ibm-plex-sans/IBMPlexSans-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('src/ibm-plex-sans/IBMPlexSans-SemiBold.woff2') format('woff2'),
        url('src/ibm-plex-sans/IBMPlexSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('src/ibm-plex-sans/IBMPlexSans-Italic.woff2') format('woff2'),
        url('src/ibm-plex-sans/IBMPlexSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('src/ibm-plex-sans/IBMPlexSans-Thin.woff2') format('woff2'),
        url('src/ibm-plex-sans/IBMPlexSans-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('src/ibm-plex-sans/IBMPlexSans-ExtraLightItalic.woff2') format('woff2'),
        url('src/ibm-plex-sans/IBMPlexSans-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('src/ibm-plex-sans/IBMPlexSans-Light.woff2') format('woff2'),
        url('src/ibm-plex-sans/IBMPlexSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('src/ibm-plex-sans/IBMPlexSans-MediumItalic.woff2') format('woff2'),
        url('src/ibm-plex-sans/IBMPlexSans-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('src/ibm-plex-sans/IBMPlexSans.woff2') format('woff2'),
        url('src/ibm-plex-sans/IBMPlexSans.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

